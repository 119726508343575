import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

const PublicationCard = ({ publication }) => {
  return (
    <div>
      <div className="px-6 py-2">
        <div className="font-bold mt-4 mb-2">
          {publication.frontmatter.name}
        </div>
        <p className="text-gray-700 text-base">
          {publication.frontmatter.reference}
        </p>
        <p className="text-gray-700 text-base underline">
          <a href={publication.frontmatter.doi}>DOI: {publication.frontmatter.doi}</a>
        </p>
      </div>
    </div>
  )
}

const PublicationImage = ({ image }) => {
  const imageStyle = {
    width: "100%",
    height: 200,
    overflow: "hidden",
  }
  return <Img style={imageStyle} fluid={image.childImageSharp.fluid} />
}

const PublicationList = ({ data }) => {
  const { edges: publications } = data.allMarkdownRemark
  return (
    <div>
      {publications &&
        publications.map(({ node: publication }, i) => (
          <PublicationCard key={publication.id} publication={publication} />
        ))}
    </div>
  )
}

export default () => (
  <StaticQuery
    query={graphql`
      {
        allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "publication-page" } } }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                name
                reference
                doi
                image {
                  id
                  childImageSharp {
                    fluid(maxWidth: 300) {
                      ...GatsbyImageSharpFluid_tracedSVG
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <PublicationList data={data} />}
  ></StaticQuery>
)
