import React from "react"

import Layout from "../../components/layout"
import PublicationList from "../../components/PublicationList"

const PublicationsPage = () => {
  return (
    <Layout>
      <div className="text-indigo-600 font-bold mt-8">Journal Publications</div>
      <PublicationList />
    </Layout>
  )
}

export default PublicationsPage
